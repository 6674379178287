
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

#app form {
  section {
    padding: 0px 0 0;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 40px;
    width: 100%
  }

  .abtestNewApplyBtn {
    box-shadow: 0px 2px 0px 0px #604DD533;
    background: rgba(96, 77, 213, 0.1) !important;
    color: rgba(96, 77, 213, 1);
    font-weight: 700;
    font-size: 20px;
    line-height: 22.04px;
  }

  .input-group {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div {
      margin-top: 0px;
      margin-bottom: 20px;
      width: 100%
    }

    &.columns {
      justify-content: space-between;
      align-items: center;

      label {
        min-width: 40%;
        max-width: calc(50% - 10px);
        margin-right: 0;

        &:nth-of-type(odd) {
          margin-right: 10px
        }

        &:nth-of-type(even) {
          margin-left: 9px /* Safari Rendering bug fix */
        }
      }
    }

    label {
      padding: 12.5px;
      max-height: 65px;
      font-size: 1.2rem;
      border: 1px solid $label;
      border-radius: 2rem;
      background: $label;
      flex-grow: 1;
      text-align: center;
      margin: 0 20px 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:checked + label {
        border: 1px solid $electric-purple;
        color: $electric-purple;
        background: #fff;
      }

      &:checked + .abtestNewApplyBtn {
        background: rgba(96, 77, 213, 1) !important;
        color: #fff;
        box-shadow: none;
      }

      &:focus + label {
        box-shadow: 0 0 0 2px $electric-purple;
      }
    }

    #other {
      &:focus + label {
        box-shadow: 0 0 0 0px;
      }
    }

    #other-label {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }

  #other {
    display: none;
  }
}
