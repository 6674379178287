@import '../mixins';

@mixin partner-theme-button-text($name, $button-text-color: #fff) {
  #app.#{$name} .button {
    color: $button-text-color !important;
  }
  #app.#{$name} .button svg {
    fill: $button-text-color !important;
  }
  #app.#{$name} .banner-text {
    text-align: center;
    font-size: 16px;
    color: $button-text-color !important;
    font-weight: bold;
  }
}