
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

#calculator-interstitial {
  .calculator-container {
    max-width: 920px;
    margin: 0 auto;
    width: 100%;
    padding-top: 15px;
  }

  .body-container {
    max-width: 920px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }

  .text-body1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.2px;
    line-height: 22px;
    @include font-regular();
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    color: #000000;
  }
  .text-subtitle1 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.2px;
    line-height: 18px;
  }
}
