
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

.cflogo-displacement {
  position: relative;
  left: -20px;
}

.-m-auto {
  margin: auto;
}

@media (min-width: 412px) {
  .cflogo-displacement {
    position: relative;
    left: -20px;
  }
}

@media (min-width: 800px) {
  .cflogo-displacement {
    position: relative;
    left: -50px;
  }

  .-m-auto {
    margin: unset;
  }
}

.partnerLogo-displacement {
  position: relative;
  left: -5px;
}

.dualband-displacement {
  max-height: 70px;
}

.outer-wrapper {

  line-height: 1.1em;

  .inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .trustpilot-container {
      display: flex;
    }

    .trustpilot {
      width: 100%;
      display: flex;
      margin: 20px auto;
      justify-content: center;
      align-items: center;
      -webkit-box-align: center;
      -webkit-box-pack: center;

      .trustpilot__logo {
        max-width: 108px;
        width: 100%;
      }

      .trustpilot__stars {
        max-width: 108px;
        margin: 0 10px;
      }

      .trustpilot__text {
         font-size: 0.9em;
        min-width: 90px;
      }
    }

    .rep-example {
      font-size: 0.9em;
    }

    .btn-alpha {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-transition: background-color .3s;
      transition: background-color .3s;
      color: #fff;
      background-color: #604dd5;
      @include font-bold();
      padding: 11px 15px;
      border: none;
      width: 100%;
      margin: 20px auto;
      line-height: 1.5rem;
      -webkit-box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      border-radius: .3rem;
      max-width: 300px;
      -webkit-appearance: none;
      text-align: center;

      @media (min-width: 320px) {
        font-size: 1.3rem;
      }

      @media (min-width: 360px) {
        font-size: 1.5rem;
      }
    }

    .btn-alpha span {
      display: block;
      letter-spacing: .6px;
      @include font-regular();
      text-align: center;

      @media (min-width: 320px) {
        font-size: 12px;
      }

      @media (min-width: 360px) {
        font-size: 16px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul li {
      list-style: none;
      position: relative;
      @include font-regular();
      background-color: #fff;
      line-height: 1.625rem;
      color: #4a4a4a;
      margin: 0;

      @media (min-width: 320px) {
        font-size: 1rem;
      }

      @media (min-width: 360px) {
        font-size: 1.2rem;
      }
    }

    ul li:before {
      content: "\2714";
      color: #5f4dd5;
      font-weight: bolder;
      width: 14px;
      height: 26px;
      background-position: 0;
      background-repeat: no-repeat;
      background-size: 14px;
      position: relative;
      display: block;
      float: left;
      margin-left: -25px;
      padding-left: 8px;
      left: -5px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .column {
    padding: 5px;
  }
}
