
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.power-by-cf247-text {
  padding: 0px;
  border-radius: 5px;
  font-size: .9em;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  margin-top: 20px;
  font-size: 1.125rem;
  text-align: left;
  padding: 15px 50px;
}
