@import '../mixins';

@mixin partner-theme-button($name, $primary-color, $button-color: $primary-color, $border-color: $primary-color) {
  #app.#{$name} .button.alpha {
    background-color: $button-color !important;
    border-color: $border-color !important;
  }
  #app.#{$name} .btn-close.btn-alpha {
    background-color: $button-color !important;
    border-color: $border-color !important;
  }
  #app.#{$name} .banner {
    background-color: $button-color !important;
    padding: 1px 0;
  }
}