
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.app-form-container {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.app-form-main {
  padding: 0 20px 40px;
}

.-mb-20 {
  margin-bottom: 20px;
}

.trustpilot-container {
  text-align: center;

  img {
    height: 35px;

    @media only screen and (max-width: 600px) {
      height: 30px;
    }

    @media only screen and (max-width: 412px) {
      height: 27px;
    }

    @media only screen and (max-width: 360px) {
      height: 25px;
    }

    @media only screen and (max-width: 320px) {
      height: 25px;
    }
  }
}

.modelV3 {
  @media not all and (min-width: 480px) {
    display: none;
  }
}

.modelMobile {
  @media only screen and (min-width: 479px) {
    display: none;
  }
}
