.progress {
	width: 100%;
	display: block;
	margin: 0 0 1rem;
	background: #fff;
	overflow: hidden;
	// todo check
	position: relative;
	position: sticky;
	top: 0;
	z-index: 10;
	padding: 10px 0;

	@media(min-width: 610px) {
		padding: 10px 0;
		border-radius: 3px;
	}

	@media (max-width: 412px) {
		margin: 0px;
		padding: 5px 0;
	}

	.bar-container {
		height: 5px;
		position: relative;
		overflow: hidden;
		margin-bottom: 10px;


		@media(min-width: 610px) {
			top: 10px;
			height: 10px;
			border-radius: 5px;
		}

		.bar {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			transition: width 1s;

			&.bg {
				background: $gray;
			}

			&.validated {
				background: $colour-valid;
			}

			&.complete {
				background: $electric-purple;
			}
		}
	}
}