// FONTS
@font-face {
	font-display: swap;
	font-family: Bariol-Regular;
	src: local("Bariol-Regular"),
	url("/fonts/bariol_regular-webfont.woff2") format("woff2"),
	url("/fonts/bariol_regular-webfont.woff") format("woff");
	font-style: normal;
}

@font-face{
	font-family: 'Helvetica-Neue-Regular';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/HelveticaNeueRegular.otf') format('otf');
}

@font-face{
	font-family: 'Helvetica-Neue-Bold';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/HelveticaNeueBold.ttf') format('ttf');
}

@mixin font-regular() {
	font-family: Bariol-Regular, -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
	font-weight: 400;
}

@mixin font-bold() {
	font-family: Bariol-Regular, -apple-system, system-ui, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
	font-weight: 700;
}

// ANIMATIONS
@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

@keyframes vodal-fade-leave {
	to {
		opacity: 0
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%, 100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px $colour-valid;
	}
}