@import '../mixins';

@mixin partner-theme-info-footer($name, $info-footer-color, $info-footer-background-color: #E8E8E8, $info-footer-text-color: #4a4a4a) {
  .#{$name} .svg-fill {
    fill: $info-footer-color !important;
  }

  #app.#{$name} .footer .top-inner{
    background-color: $info-footer-background-color !important;
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 3px;
    font-size: .9em;
    color: $info-footer-text-color !important;
  }

  #app.#{$name} .footer .top-inner svg g path:first-of-type {
    fill: $info-footer-background-color !important;
  }

  #app.#{$name} .footer .top-inner svg g path:nth-of-type(2) {
    fill: $info-footer-text-color !important;
  }

  #app.#{$name} .footer .bottom-inner {
    max-width: 610px;
    background-color: $info-footer-background-color !important;
    margin: 0 auto;
  }
}