input {
	&[type='text'],
	&[type='number'],
	&[type='tel'],
	&[type='email'] {
		border: none;
		border-bottom: 1px solid $dark-gray;
		width: 100%;
		padding: 10px 0;
		font-size: 1.6rem;
		-webkit-appearance: none;
		border-radius: 0;

		@media (min-width: 1024px) {
			font-size: 1.75rem;
		}

		&:focus {
			outline: none;
			border-bottom: 1px solid $electric-purple;
		}

		&.error {
			border-bottom: 1px solid $colour-error;
		}

		&.valid {
			border-bottom: 1px solid $electric-purple;
		}
	}

	&[type='number'] {
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

div {
	&.input-container {
		padding: 20px 0 30px;
		position: relative;

		&.small-input {
			max-width: 50%
		}
	}
}

select {
	border: none;
	border-bottom: 1px solid $dark-gray;
	width: 100%;
	padding: 10px 0;
	font-size: 1.6rem;
	border-radius: 0;
	background-color: #fff;
	-webkit-appearance: none;
	-webkit-border-radius: 0px;

	&:focus {
		outline: none;
		border-bottom: 1px solid $electric-purple;
	}

	&.error {
		border-bottom: 1px solid $colour-error;
	}

	&.valid {
		border-bottom: 1px solid $electric-purple;
	}
}