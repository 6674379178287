$white:             #fff;
$chrome:            #f2f2f2;
$light-gray:        #f5f5f5;
$gray:              #e5e5e5;
$dark-gray:         #aaa;
$heavy-gray:        #888;
$dark-chrome:       #4a4a4a;
$light-black:       #2e2e2e;
$almost-black:      #222;
$jet-black:         #111;
$pale-purple:       #9387d8;
$electric-purple:   #604dd5;
$royal-purple:      #503fb6;
$dark-purple:       #442f70;
$royal-blue:        #211850;

$forest-green:      #229668;
$deep-green:        #10a3b8;
$blue-sky:          #6883fe;
$brick-red:         #cf563f;

$dust-blue:         #c9d3ff;
$fiesta-yellow:      #f6cc1b;
$energy-green:      #6accb6;

$colour-type:       $dark-chrome;
$colour-selection:  #c9d3ff;
$colour-error:      #ec0000;
$colour-valid:      $forest-green;
$label:             #efefef;
$colour-hover:     #725bfc;

$transitionSpeed: 0.3s;

$primary: #604dd5;