footer {
	padding: 60px 20px 40px;

	section {
		display: flex;
		justify-content: space-between;

		> * {
			flex-grow: 1;
		}

		address {
			margin-right: 10px;
			padding: 10px 0;

			abbr {
				text-decoration: none
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			a {
				padding: 10px 0;
			}
		}
	}
}