
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.vehicle-banner{
  text-align: center;
}
.container {
  padding-top: 3px;

  &__image {

    display: inline-block;

    border-radius: 50%; 
    height: 150px;
    width: 150px;
    margin-right: 25px;
    background-size : cover;
    @media (max-height: 740px) {
      height: 80px;
      width: 80px;
    }
  }

  &__text {
    display: inline-block;

    height: 150px;
    font-size: 1.2rem;
    line-height: 1.22rem;

    text-align: left;

    @media (max-height: 740px) {
      height: 80px;
      width: 80px;
      font-size: 0.99rem;
    }

    &__table {
      display: table; 
      height: 100%;

      &__row {
        display: table-row;
         height: 100%;
        &__cell {
          display: table-cell;
          vertical-align: middle;
           height: 100%;

        }
      }
    }
  }

}

  section {
    border-bottom: 2px solid #ECECEC;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
