
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.button-wrapper {
  display: block;

      @media (max-width: 800px) {
        position: fixed;
        right: 20px;
        z-index: 9999;
    }
}
.modal {
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  border-radius: 10px;
  width: 100%;
  // padding: 25px; remove
  position: relative;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  margin: 25px auto;
  max-width: 932px;
  max-height: 600px;
  min-width: 932px;
  min-height: 600px;

  // @media screen and (max-height: 690px),
  //        screen and (max-width: 412px) {
  //   max-height: 100%;
  // }

  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    z-index: 11;
    overflow: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    position: relative;
  }

  header {
    padding: 0;
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 28px;
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
    top: 0;
    right: 0;
    float: right;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    color: #DEDEDE;
    font-weight: 400;

    svg {
      display: block;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-leave-active {
  -webkit-animation: vodal-fade-leave both ease-out;
  animation: vodal-fade-leave both ease-out;
}

.modal-container {
  // display: inline-block; remove
  // overflow-y: auto; remove
  // overflow-x: hidden; remove
  // width:100%; remove
  display: table;
  width: 100%;
}

.left-half > div {
  height: 100%;
  background-color: #F8F8F8;
}

.left-half {
  position: absolute;
  width: 412px;
  height: 600px;

}

.left-bg1 {
  background-image:  url("../assets/img/model_v3_bg_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.left-bg1 img {
  position: absolute;
  width: 422.83px;
  height: 206.3px;
  left: 37px;
  top: 231px;
}

.right-half {
  position: absolute;
  right: 0px;
  width: 50%;
  
}
