@import '../mixins';

@mixin partner-theme($name, $primary-color, $secondary-color: $primary-color, $border-radius: 2rem) {
  #app.#{$name} form .input-group input:checked + label {
    border-color: $secondary-color !important;
    color: $primary-color !important;
  }
  #app.#{$name} form .input-group input:focus + label {
    box-shadow: 0 0 0 2px $secondary-color !important;
  }
  #app.#{$name} .button.charlie {
    color: $secondary-color !important;
  }
  .#{$name} input[type='text']:focus, .#{$name} input[type='number']:focus, .#{$name} input[type='tel']:focus, .#{$name} input[type='email']:focus, #app.#{$name} form .container.valid {
    border-color: $secondary-color !important;
  }
  #app.#{$name} form .checkbox-container input:focus ~ label .checkmark, #app.#{$name} form .checkbox-container:hover input ~ label .checkmark {
    box-shadow: 0 0 0 2px $secondary-color !important;
  }
  #app.#{$name} form .checkbox-container input:checked ~ label .checkmark {
    background-color: $primary-color !important;
    box-shadow: 0 0 0 1px $secondary-color !important;
  }
  #app.#{$name} form .input-container input:focus + .labelWrapper .textlabel, #app.#{$name} form .input-container :not([value=""]) + .labelWrapper .textlabel {
    background-color: white;
    font-size: 0.8125rem;
    color: $primary-color !important;
    transform: translate(0, -240%);
    @include font-bold();
  }
  #app.#{$name} form input[type='text'].valid, #app.#{$name} form input[type='number'].valid, #app.#{$name} form input[type='tel'].valid, #app.#{$name} form input[type='email'].valid {
    border-bottom: 1px solid $secondary-color !important;
  }
  #app.#{$name} .button.bravo svg {
    margin-left: 0;
    margin-right: 0.5rem;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    fill: $primary-color !important;
  }
  #app.#{$name} .button.charlie svg {
    margin-left: 0;
    margin-right: 0.5rem;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    fill: $secondary-color !important;
  }
  #app.#{$name} form .date-mod:focus-within {
    outline: none;
    border: 2px solid $secondary-color !important;
  }
  #app.#{$name} form .date-mod:focus-within > .labelDateWrapper .textlabel {
    position: absolute;
    z-index: 999;
    left: 1rem;
    transform: translate(0, -285%);
    display: block !important;
    font-size: 0.8125rem;
    border: 1px solid white;
    background-color: white;
    color: $primary-color !important;
    @include font-bold();
  }
  #app.#{$name} form .input-container input:focus + .labelWrapper .textlabel, #app.#{$name} form .input-container .container.focus .pound{
    border-color: $secondary-color !important;
    color: $primary-color !important;
  }

  #app.#{$name} .outer-wrapper .inner-wrapper ul li:before {
    content: "\2714";
    color: $primary-color !important;
    font-weight: bolder;
    width: 14px;
    height: 26px;
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 14px;
    position: relative;
    display: block;
    float: left;
    margin-left: -25px;
    padding-left: 8px;
    left: -5px;
  }

  #app.#{$name} .svg-theme-color {
    fill: $primary-color !important;
  }

  .#{$name} .fraud a, .#{$name} .tAndCArea a, .#{$name} .privacy-banner a{
    color: $secondary-color !important;
  }

  #app.#{$name} .icon-info path {
    fill: $primary-color !important;
  }

  #app.#{$name} form .input-group label {
    padding: 12.5px;
    max-height: 65px;
    font-size: 1.2rem;
    border: 1px solid #efefef;
    border-radius: $border-radius !important;
    background: #efefef;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    margin: 0 20px 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
}
