
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.button-wrapper {
  display: block;

      @media (max-width: 800px) {
        position: fixed;
        right: 20px;
        z-index: 9999;
    }
}
.modal {
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  border-radius: 4px;
  width: 100%;
  padding: 25px;
  position: relative;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  margin: 25px auto;
  max-width: 800px;
  max-height: 80%;

  @media screen and (max-height: 690px),
         screen and (max-width: 412px) {
    max-height: 100%;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    z-index: 11;
    overflow: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    position: relative;
  }

  header {
    padding: 0;
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
    top: 0;
    right: 0;
    float: right;
    border-radius: 20px;
    transition: 0.3s ease-in-out;

    svg {
      display: block;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-leave-active {
  -webkit-animation: vodal-fade-leave both ease-out;
  animation: vodal-fade-leave both ease-out;
}

.modal-container {
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  width:100%;
}
