
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.rep-example {
    & p {
        text-align: center;
        padding-top: 0px;


        &:first-child {
            font-size: 1rem;

            @media
            (min-width: 360px),
            only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
            only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
            only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
            only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
               font-size: 1rem;
            }

            @media (min-width: 768px) {
                font-size: 1.0rem;
            }
        }

        @media
        (min-width: 360px),
        only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
        only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
        only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
        only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
            font-size: 1rem;
        }
    }
}

