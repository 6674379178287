
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

#app form {
  .duel-inputs {
    display: flex;
    justify-content: space-between;

    .small-input {
      max-width: calc(50% - 20px)
    }
  }
}

.app-duration {
  display: flex;
  justify-content: center;
  margin-top: 25px;

  div {
    font-size: 1.15em;
    line-height: 30px;
  }
}
.checked {
  margin-right: 15px;
}
