$affiliates: (
  (
    theme:              'autovillage',
    primary:            #0088c4
  ),
  (
    theme:              'allpurposeloans',
    primary:            #6f42c1
  ),
  (
    theme:             'carcraft',
    primary:            #ff916f,
    button-text:        #003149,
    progress-bar:       #003149
  ),
  (
    theme:             'carcredible',
    primary:            #1b9bfc,
  ),
  (
    theme:             'cargurus-submission',
    primary:            #008002
  ),
  (
    theme:             'carsite',
    primary:            #669552
  ),
  (
    theme:             'carsnitch',
    primary:            #343a40
  ),
  (
    theme:             'ckdirect',
    primary:            #008600,
    secondary:          #21292c,
    progress-bar:       #21292c,
    testB-footer-block: #00a700
  ),
  (
    theme:             'confused',
    primary:            #1f1f1f,
    secondary:          #ea9665,
    progress-bar:       #ea9665
  ),
  (
    theme:             'credability',
    primary:            #8100ff,
    button-border-color: #49454E
  ),
  (
    theme:             'creditkarma',
    primary:            #008600
  ),
  (
    theme:             'desperateseller',
    primary:            #336699
  ),
  (
    theme:             'equifax',
    primary:            #29343f
  ),
  (
    theme:             'gowizard',
    primary:            #5621aa
  ),
  (
    theme:             'honestjohn',
    primary:            #023a6d
  ),
  (
    theme:              'oceanfinance',
    primary:             #008fd0,
    button-color:        #349702,
    button-border-color: #349702,
    info-footer: (
      button-color: #349702,
    )
  ),
  (
    theme:              'oceancar',
    primary:             #282d5c,
    button-color:        #349702,
    button-border-color: #349702,
    info-footer: (
      button-color: #349702,
    )
  ),
  (
    theme:             'parkers',
    primary:            #034ea2
  ),
  (
    theme:             'powerby',
    primary:            #b71c1c
  ),
  (
    theme:             'thinkmoney',
    primary:            #0078b2
  ),
  (
    theme:             'thinkmoneycar',
    primary:            #0078b2,
    secondary:          #474747
  ),
  (
    theme:             'clearscore',
    primary:            #0f81a3
  ),
  (
    theme:              'credapp',
    primary:             #8100ff
  ),
  (
    theme:             'experian',
    primary:            #d82b80
  ),
  (
    theme:             'cheapcarsforsale',
    primary:            #0064FF
  ),
  (
    theme:             'drivescore',
    primary:            #0B132E
  ),
  (
    theme:             'mycarcheck',
    primary:            #12325b
  ),
  (
    theme: 'comparethemarket',
    primary: #003fd8
  ),
  (
    theme: 'comparethemarketcarfinance',
    primary: #2843D0,
    info-footer: (
        color: #FFFFFF,
        background-color: #2843D0
    ),
    testB-footer-block: #0e4a8e
  ),
  (
    theme:     'pistonheads',
    primary:   #1C2527
  )
);