
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer .inner {
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #F8F7FA;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center ;

    gap: 5px;
    flex-shrink: 0;
}

.footer .inner .block{
  background-color: #fff;

  border-radius: 15px;
  align-items: center;
  justify-content: top;
  display: flex;
  flex-direction: column;

  font-size: 12px;
  line-height: 11px;

  max-width: 98px;
  min-width: 80px;
  height: 80px;

  padding: 5px;
  flex-basis: 100%;
}

.footer .inner .block .icon {
  height: 35px;
  margin-bottom: 5px;
}

.footer .top-inner{
    background-color: #E8E8E8;
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 3px;
    font-size: .9em;
    color: #4a4a4a;
}

.footer .top-inner svg g path:first-of-type {
    fill: #E8E8E8;
}

.footer .top-inner svg g path:nth-of-type(2) {
  fill: #4a4a4a;
}

.footer .bottom-inner {
    max-width: 610px; 
    background-color: #E8E8E8;
    margin: 0 auto;
}

.footer .no-bg-bottom-inner {
  max-width: 610px;
  margin: 0 auto;
}

.footer .top-inner div, .footer .top-inner svg {
    vertical-align: middle;
    display: inline-block;
    font-size: 0.8em;
}



.footer .top-inner svg {
   width: 0.8em;
}

  @media (max-width: 320px) {
    .footer .top-inner div {
      font-size: 0.7em;

    }
    .footer .top-inner svg {
      width: 0.7em;
    }
  }

.svg-fill {
    fill: $primary;
}
