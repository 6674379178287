
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

p {
  text-align: center;

  &:first-of-type {
    margin-top: 20px;
  }



  @media
  only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
  only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
    font-size: 1rem;
  }
}

ul {
  padding-left: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 340px) {
    margin: 0px 33%;
    width: 50%;
  }

  @media
  only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
  only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
    margin: 0px 5%;
    width: 240px;
  }

  @media (min-width: 768px) {
    margin: 0px 33%;
    width: 50%;
  }

  li {
    list-style: none;
    position: relative;

    &:before {
      content: "";
      width: 14px;
      height: 26px;
      background-image: url("/images/check.svg");
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 14px;
      position: relative;
      display: block;
      float: left;
      margin-left: -15px;
      padding-left: 8px;
    }

    @media
    only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
    only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
    only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
    only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
      font-size: 1rem;
    }
  }
}

.btn-alpha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color $transitionSpeed;
  color: $white;
  background-color: $electric-purple;
  @include font-bold();
  padding: 11px 15px;
  border: none;
  width: 100%;
  margin: 20px auto;
  font-size: 1.5rem;
  line-height: 1.5rem;
  box-shadow: 0 6px 10px 0 rgba($electric-purple, 0.15);
  border-radius: 0.3rem;
  -webkit-appearance: none;

  @media (min-width: 360px) {
    max-width: 340px;
  }

  @media (min-width: 320px) {
    max-width: 300px;
  }

  @media
  only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
  only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
  only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
    font-size: 1.3rem;
  }

  &:hover {
    background-color: $colour-hover;
    cursor: pointer;
  }

  span {
    display: block;
    font-size: 16px;
    letter-spacing: 0.6px;
    @include font-regular();
    text-align: center;

    @media
    only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
    only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
    only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
    only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
      font-size: 12px;
    }
  }
}
