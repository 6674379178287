
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

#creditkarma-interstitial {

  h2 {
    font-weight: bolder;
    font-size: 18px;
    margin: 0px 0px 20px;
  }

  >div {
    >div {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;

      li, p {
        font-size: 14px;
      }
    }
  }

  #representative-example {
    margin: 50px 10px 20px;

    p {
      text-align: center;
      font-size: 14px;
    }
  }

  #info {
    background-color: #DFCFEB;
    padding: 10px 0px;

    h2 {
      margin: 20px 10px 20px;
    }

    p {
      margin: 0px 10px 20px;
    }
  }

  #cta {
     background-color: #CCE3D1;
     padding: 30px 0px;

    .flex-box {
      display: flex;
      align-items: normal;
      flex-wrap: nowrap;
      justify-content: center;

      div:first-child {
        width: 350px;
      }
    }

    .img-container {
      text-align: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    @media only screen and (max-width: 600px) {
      .flex-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap-reverse;
        justify-content: center;

        > div {
          width: 100%;
        }
      }

      .img-container {
        text-align: center;

        img {
          width: auto;
          height: 200px;
        }
      }
    }

    ul {
      list-style-position: outside;
      padding-left: 0;
      margin-left: 15px;
    }

    .button-info-text {
      font-size: 14px;
      text-align: center;
    }
  }
}
