
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        


.addresses {
  overflow-x: hidden;
  height: 13em;
  padding: 0;
  margin: 0;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}

#address-select {
  font-size: 1.2rem;
}

.address {
  display: block;
  font-size: 1.2rem;
  border-bottom: 1px solid #bbbbbb;
  color: #7f7f7f;
  padding: 10px;
  margin: 5px;
}

.address-summary {
  border: 1px solid #bbbbbb;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 10px;
}

.address-summary-text {
  max-width: 80%;
}

.pencil {
  margin-bottom: 20px;
}

.pencil:hover {
  cursor: pointer;
}

.address-item:hover {
  color: white;
  cursor: pointer;
  background-color: #e5e5e5;
  width: 100%;
}

.address-item {
  font-size: 1.3rem;
  margin: 5px 0;
}

.manual-text {
  display: flex;
  align-items: center;
  color: #7f7f7f;
  margin: 5px 0;
}

.manual-enter-text {
  color: #604dd5;
}

.manual-text:hover {
  cursor: pointer;
  background-color: #e5e5e5;
  width: 100%;
}

.postcode-input {
  width: 100%;
}

textarea:focus, input:focus{
  outline: none;
}

@media only screen and (max-width: 400px) {
  .address-summary-text {
    max-width: 90%;
  }
}

.manual-address-back {
  cursor: pointer;
}

.magnifier-icon{
  @include font-regular();
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
}

.magnifier-text {
  position: static;
  width: 155px;
  height: 23px;
  left: 24px;
  top: 0px;
  @include font-regular();
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #5A52CD;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
}
