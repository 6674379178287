
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

.app-duration {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    div {
      font-size: 1.15em;
      line-height: 30px;
    }
}
.checked {
    margin-right: 15px;
}
.green-tick {
  display: flex;
  justify-content: center;
  margin-top: 25px;

  div {
    position: relative;
    top: -20px;
    left: 10px;
    font-size: 18px;
    line-height: 20px;

    @media (min-width: 1024px) {
      position: relative;
      top: 0px;
      left: 0px;
      font-size: 18px;
    }
  }
}
