@import '../mixins';

@mixin affiliate-test-b($name, $primary-color, $secondary-color: $primary-color, $border-radius: 2rem) {
  #app:is(.testB).#{$name} header {
    background-color:$primary-color ;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #app:is(.testB).#{$name} .progress {
    background-color:$primary-color ;
    padding-bottom: 25px;
    margin: 0px;
    border-radius: unset;
  }
  #app:is(.testB).#{$name} .progress .bar-container {
    margin-bottom: unset;
    border-radius: unset;
  }

  #app:is(.testB).#{$name} .-pb-10:has(> .trustpilot-container) { 
    background-color: $primary-color;
  }

  #app:is(.testB).#{$name} .testB-container{
      position: relative;
      width:100%;
      height:200px;
      overflow: hidden;
      margin-bottom: 25px;
      display: block !important;
      padding-left: 20px;
      padding-right: 20px;
  }

  #app:is(.testB).#{$name} .testB-container span {
    color: white;
    font-size: .8em;
  }
  #app:is(.testB).#{$name} .testB-container h1 {
    color: white;
  }
  #app:is(.testB).#{$name} .testB-container:after {
      width: 140%;
      height: 200px;
      position: absolute;
      left: -20%;
      top:0;
      z-index: -1;
      content: '';
      border-radius: 0 0 70% 35%;
      background: $primary-color;
  }

  #app:is(.testB).#{$name} .privacy-banner a {
    color: $primary-color !important;
  }

  #app:is(.testB).#{$name} .testB-container, #app:is(.testB).#{$name} .testB-container:after {
    height: 200px;

    @media only screen and (max-width: 600px) {
      height: 200px;
    }

    @media only screen and (max-width: 412px) {
      height: 220px;
    }

    @media only screen and (max-width: 360px) {
      height: 230px;
    }

    @media only screen and (max-width: 320px) {
      height: 240px;
    }
  }
}

@mixin affiliate-theme-info-footer-testB($name, $primary-color, $info-footer-block-color) {
    #app:is(.testB).#{$name} .icon .testB {
      fill: $info-footer-block-color ;
    }
  
    #app:is(.testB).#{$name} .icon .testB-stroke {
      stroke: white !important;
    }
  
    #app:is(.testB).#{$name} .icon .testB-outline {
      fill: white !important;
    }
  
    #app:is(.testB).#{$name} .block {
      color: white;
    }
  
    #app:is(.testB).#{$name} .footer .inner {
      background-color: $primary-color;
      border-radius: unset;
      padding-left:25px;
      padding-right: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  
    #app:is(.testB).#{$name} .footer .inner .block {
      background-color: $info-footer-block-color;
      padding-top: 10px;
      border-radius: 3px;;
    }
}