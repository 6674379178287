
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

footer {
  max-width: 940px;
  margin: 0 auto;
  width: 100%;
  padding-top: 200px;

  @media (min-width: 360px) {
    p {
      font-size: 0.7em;
    }
  }

  @media (min-width: 768px) {
    p {
      font-size: 0.8em;
    }
  }
}

hr.separator {
  border: 0;
  margin: 0;
  box-shadow: inset 0 1px 0 0 #e5e5e5;
  height: 5px;
}

p.center {
  text-align: center;
}

p.mt-100 {
  margin-top: 100px;
}

a.no-text-decoration {
  text-decoration: none;
}
