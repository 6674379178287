
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

	#app form {
    h2 {
      color: #4a4a4a;
    }
		p {
			&.error {
				margin-bottom: 0;
			}

			&.birthday {
				text-align: center;
				font-size: 2rem;
				line-height: 1.5;
			}
		}

		section {
			padding: 20px 0 30px;
			position: relative;
		}

		div {
			&.small-input {
				max-width: 50%
			}

			&.duel-inputs {
				display: flex;
				justify-content: space-between;
        align-items: center;
				padding: 0;

				.small-input {
					max-width: calc(33% - 10px);
				}
			}
		}
	}
