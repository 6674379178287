
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

a {
  text-decoration: none;
}

.privacy-banner {
  padding: 0px;
  //background: $gray;
  border-radius: 5px;
  font-size: .9em;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  margin-top: 20px;
  font-size: 1.125rem;
  text-align: left;
  padding: 15px 50px;
}

.privacy-banner-fixed {
  position: fixed;
  bottom: 100px;
  left: 5px;

  @media (min-width: 1024px) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
