
          @import "@/assets/scss/_variables.scss";
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_inputs.scss";
          @import "@/assets/scss/_progress.scss";
          @import "@/assets/scss/_footer.scss";
        

#app form {
  .checkbox-container {
    padding: 10px 0;

    &.alternative-loans {
      padding: 0;

      .checkmark {
        display: none;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      ~ label .checkmark-error {
        box-shadow: 0 0 0 2px $brick-red;
      }

      &:focus ~ label .checkmark {
        box-shadow: 0 0 0 2px $electric-purple;
      }

      &:checked ~ label .checkmark {
        background-color: #604dd5;
        box-shadow: 0 0 0 1px $electric-purple;

        &:after {
          display: block;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;

      .checkmark:after {
        left: 9.5px;
        top: 5px;
        width: 7px;
        height: 13px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.align-top {
        align-items: flex-start;

        span {
          margin-top: .25rem
        }
      }

      p {
        margin: 0;
        width: calc(100% - 30px);
      }
    }

    .checkmark,
    .checkmark-error {
      display: inline-block;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #fff;
      box-shadow: 0 0 0 1px $dark-chrome;
      border-radius: 2px;
      margin-right: 1rem;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ label .checkmark {
      box-shadow: 0 0 0 2px $electric-purple;
    }
  }
}
